<!-- 商品详情 -->
<template>
	<div :class="themeClass" class="max-width1100 margin-tb-20" id="shopCar">
		<div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="flex-colum">
			<div class="flex-row top-box">
				<div class="box-left margin-r-20">
					<div class="outline-imgs position-relative">
						<div class="position-relative border-F2F2F2" style="width: 347px;height: 347px;" v-if="videoShow">
							<div class="position-absolute iconfont icon-guanbi font-size30 font-weight700 delete-btn pointer" @click="videoShow = false"></div>
							<video ref="myvideo" controls="controls" :autoplay="videoShow?'autoplay':''" style="width: 100%;height: 100%;">
								<source :src="listData.goodsVideos ? listData.goodsVideos[0].fPath == '' ? null : listData.goodsVideos[0].fullFPath : null" />
							</video>
						</div>
						<div class="outline-img position-relative" v-else>
							<piczoom :url="showPicUrl.fPicturePath" :scale="3"/>
							<div v-if="listData.goodsVideos && listData.goodsVideos[0].fPath != ''" class="position-absolute font-size48 font-color-fff play-img"
							 style="bottom: 50px;left: 43.5%;opacity: 1;" @click="videoShow = true"></div>
						</div>
						<div class="outline-piclist">
							<div class="left-arrow margin-t-20" v-if="picurl.length > 1" @click="leftImg">◀</div>
							<div class="right-arrow margin-t-20" v-if="picurl.length > 1" @click="rightImg">▶</div>
							<div class="piclist-box" ref="piclist">
								<div class="piclist-img" v-for="(value,index) in picurl" :key="index" :class="{'active':showPicUrl.fPicturePath == value.fPicturePath}"
								 @click="imgClick(value,index)">
									<img :src="value.fPicturePath" />
								</div>
							</div>
						</div>
						<div v-if="hasShowAl" class="position-absolute activity-img-box" :class="{'iconTopRight': activityInfo.fDispArea==0, 'iconBottomRight': activityInfo.fDispArea==1, iconBottomLeft: activityInfo.fDispArea==2,iconTopLeft:activityInfo.fDispArea==3}">
							<img class="activityImg position-absolute" :class="{'iconTopRight': activityInfo.fDispArea==0, 'iconBottomRight': activityInfo.fDispArea==1, iconBottomLeft: activityInfo.fDispArea==2,iconTopLeft:activityInfo.fDispArea==3}"
							 :src="activityInfo.fIconPath" />
						</div>
						<!--是否已售罄-->
						<div   v-if='listData.fStatus == 2 ? true:false'   class='box-sellout'>
						  <img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
						</div>
					</div>
				</div>
				<div class="box-right flex-colum">
					<div class="flex-row margin-b-5">
						<div class="tag-box font-size12 font-color-fff radius2 margin-r-5" v-if="listData.fShopTypeID == 1">
							{{listData.fShopTypeName}}
						</div>
						<div class="font-size18 font-weight700" style="line-height: 18px;">{{listData.fGoodsFullName}}</div>
					</div>

					<!-- 活动条幅 -->
					<div v-if="hasActivity" class="activity-box flex-row-space-between  font-color-fff font-size18" style="background: #E1251A;"
					:style="hasActivity?'border-radius:10px 10px 0 0;':''">
						<div style="margin: 0 13px;width:100%" :style="hasActivity?'border-bottom:1px dashed #fff':''" class='flex-row-space-between'>
							<div class="flex-row-align-center">
								<div class="font-weight700">
									{{activityInfo.fEventName}}
								</div>
							</div>
							<div class="flex-row-align-center font-size12">
								<span class="margin-r-10 font-size16">{{showactivitytext}}</span>
								<countDowm :remainTime="usedTime" :showBold='false' @countDownEnd="countDownEnd" bgColor="#ffffff"></countDowm>
							</div>
						</div>
					</div>
					<div class="commodityInformation-box  font-size13 bg-FFFAF9 font-color-666  padding-lr-13  "  
					style="" :class="hasActivity?'activityBagBox padding-tb-5 ':'StandardBcgBox  padding-tb-10 margin-t-15'">
						<div class="flex-row-space-between">
                             <div class="flex-row-space-between" style="width:100%">
								 <div   class="flex-row-space-between-center">
								 	<div class="tatle-box font-color-999 font-size13" 
								 	:style="hasActivity?'color:#fff':'color:#1E1E1E'">
								 		现价
								 	</div>
								 	<span class="margin-r-10 font-size25 font-weight700 font-color-FF0000" :style="hasActivity?'color:#fff':''">
								 		<span  v-if='true'  class="font-size20">¥</span>
								 		<span v-if='fSmallMinPrice == priceRange ? false:true'>{{fSmallMinPrice|NumFormat}}~</span>
								 		{{priceRange|NumFormat}}
								 		<span class="font-size20" :style="hasActivity?'font-size:13px;color:#fff':'color:#1E1E1E;font-weight:400;font-size:13px'">元/{{listData.fUnitName}}
								 		</span>
								 	</span>
								 </div>
								 <div @click="star" class="flex-row-space-between-center">
								 	<div v-if="collection">
								 		<span class="iconfont icon-wujiaoxingxingxingshoucangmoren margin-r-5 font-color-FF0000 font-size13"
										 :style="hasActivity?'color:#fff':''"></span>
								 		<span :style="hasActivity?'color:#fff':''">收藏成功</span>
								 	</div>
								 	<div v-else >
								 		<span class="iconfont icon-wujiaoxing margin-r-5 font-color-FF0000 font-size13"
										:style="hasActivity?'color:#fff':''"></span>
								 		<span :style="hasActivity?'color:#fff':''">收藏商品</span>
								 	</div>
								 </div>
							 </div>
							<div class="flex-row">
								<!-- <div class="flex-column-center-center padding-r-5 margin-r-5 border-r-F2F2F2">
									<div class="font-size16 font-weight700">{{listData.price}}</div>
									<div>累计评价</div>
								</div> -->
								<!-- <div class="flex-column-center-center">
									<div class="font-size16 font-weight700">{{listData.price}}</div>
									<div>交易成功</div>
								</div> -->
							</div>
						</div>
						<div class="flex-row" v-if="listData.fActivityIntegralMultiple > 1" :style="hasActivity?'color:#fff':''">
							<div class="tatle-box font-color-999" :style="hasActivity?'color:#fff':''">
								活动
							</div>
							<span class="font-color-FF0000 margin-r-5"
							 :style="hasActivity?'color:#fff':''">赠</span>{{listData.fActivityIntegralMultiple}}倍积分
						</div>
						<div class="flex-row">
							<div  :class="changeStyle">
								<div class="flex-row" v-if="couponShow">
									<div class="tatle-box">
									</div>
									<span class="font-color-FF0000 margin-r-5 pointer" style="border-bottom: 1px solid #FF0000;"
									 :style="hasActivity?'color:#fff;border-bottom:1px solid #fff':''"  @click="toCouponCollection">去领取优惠券></span>
									
								</div>
							
									<!-- <div class="">
										<span class="iconfont icon-fenxiang1 margin-r-5 font-color-FF0000"></span>
										<span>分享至</span>
									</div> -->
							</div>
							<div v-if="CheckGoodsShow ==1">
								<span class="margin-r-5 pointer" style="border-bottom: 1px solid #0173FE;color: #0173FE;"
								 :style="hasActivity?'color:#fff;border-bottom:1px solid #fff':''"  @click="toTask">本商品已参与任务集市奖励活动></span>
							</div>
						</div>

						<div class="margin-b-10 flex-row-right">

						</div>
						<!-- <div class="margin-b-10 flex-row">
							<div class="font-color-999 tatle-box">
								积分
							</div>
							{{listData.integral}}
						</div> -->
						<div class="margin-l-65 font-color-FA2C60 margin-b-10 flex-row" v-for="(item,index) of collectCouponsPopupData"
						 :key="index">
							<!--  v-for="(item,index) of listData.couponlist" :key="index" -->
							<div class="margin-r-10">
								店铺<span class="dashed"></span>优惠券
							</div>
							<div class="margin-r-20 font-color-666">
								{{item.fCouponName}}
							</div>
							<div class="receive-box pointer" v-if="item.fReceiveStatus == 0" @click="receiveClick(item.fReceiveStatus,item.fCouponID)">
								领取
							</div>
							<div class="font-color-666" v-else>
								已领取
							</div>
						</div>
					</div>
					<div class="padding-t-13 font-color-666 commodityInformation-box">
						<!-- <div class="flex-row-space-between-center margin-b-15">
							<div class="flex-row-space-between-center">
								<div class="font-color-999 padding-tb-5 tatle-box">
									现价
								</div>
								<div class="margin-r-35">
									<span class="iconfont icon-wancheng- margin-r-5 font-color-FF0000"></span>
									<span>退款保证</span>
								</div>
								<div class="">
									<span class="iconfont icon-wancheng- margin-r-5 font-color-FF0000"></span>
									<span>物流配送</span>
								</div>
							</div>
						</div> -->
						<!-- <div class="margin-b-10 flex-row-align-center">
							<div class="font-color-999 padding-tb-5 tatle-box">
								关键属性
							</div>
							{{listData.goodsIskeyAttribute}}
						</div> -->
						<div class="flex-colum font-size13">
							<div class="flex-row margin-b-13 padding-lr-13" v-for="(item,index) of saleAttributeList" :key="index">
								<div class="font-color-1e1e1e padding-tb-5 tatle-box flex-row-space-between-center" >
									{{item.fClassPropertie}}
								</div>
								<div class="margin-r-15 font-color-000" v-for="(item1,index1) of item.goodsClassPropertieDatas" :key="index1">
									<div class="factory-box padding-5 border-F2F2F2 text-center pointer-color-theme factory-hover" :class="item1.checked?'bcgEAF3FF border-theme color-theme':''"
									 @click="saleAttributeClick(item.fClassPropertieID,item1.fClassPropertieDataID)">{{item1.fClassPropertieData}}</div>
								</div>
							</div>
							<div class="flex-row margin-b-13 padding-lr-13" v-for="(item,index) of otherAttributeList" :key="item.fClassPropertieID">
								<div class="font-color-1e1e1e padding-tb-5 tatle-box flex-row-space-between-center">
									{{item.fClassPropertie}}
								</div>
								<div class="margin-r-15 font-color-000" v-for="(itemDetailed,detailedIndex) of item.detailed" :key="itemDetailed.fGoodsPropertieDataID">
									<div class="factory-box padding-5 border-F2F2F2 text-center pointer-color-theme factory-hover" :class="itemDetailed.checked?'bcgEAF3FF border-theme color-theme':''"
									 @click="otherAttributeClick(item.fClassPropertieID,itemDetailed.fGoodsPropertieDataID)">{{itemDetailed.fGoodsPropertieData}}</div>
								</div>
							</div>
							<!-- <div class="flex-row margin-b-10 padding-lr-13" v-if="isItAvailable">
								<div class="font-color-999 padding-tb-5 tatle-box">
									提货方式
								</div>
								<div class="margin-r-15 font-color-000" v-for="(item,index) of deliveryMethodList" :key="index">
									<div class="factory-box padding-tb-5 border-F2F2F2 text-center pointer-color-theme factory-hover" :class="item.checked?'bg-F8F9FE border-theme color-theme':''"
									 @click="deliveryMethodClick(item.fDeliveryID)">{{item.fDelivery}}</div>
								</div>
							</div> -->
							<!-- <div class="flex-row-align-center margin-b-10">
								<div class="font-color-999 tatle-box">
									配送地
								</div>
								<div class="flex-row-wrap">
									<el-popover placement="bottom" width="500" trigger="click" v-model="popoverShow">
										<el-tabs v-model="addressActiveName">
											<el-tab-pane label="地址" name="first"> -->
							<!-- <el-select v-model="fGovernmenAreaID[3]" placeholder="请选择地址">
													<el-option v-for="item in addressList" :key="item.fGovernmenAreaID" :label="item.fFullPathAddress" :value="item.fGovernmenAreaID">
													</el-option>
												</el-select> -->
							<!-- <div :class="themeClass" v-for="(item,index) in addressList" :key="index">
													<div class="pointer" :class="item.checked?'color-theme':''" @click="addressClick(item)">
														{{item.fFullPathAddress}}
													</div>
												</div>
											</el-tab-pane>
											<el-tab-pane label="选择其他地址" name="second">
												<selectArea v-if="addressActiveName == 'second'" v-model="fGovernmenAreaID" @input="ChooseArea"></selectArea>
											</el-tab-pane>
										</el-tabs>
										<el-button slot="reference" class="width-fill">{{deliveryAddress?deliveryAddress:'选择地址'}}</el-button>
									</el-popover>
								</div>
							</div> -->
							<div class="flex-row margin-b-13 padding-lr-13">
								<div class="font-color-1e1e1e padding-tb-5 tatle-box flex-row-space-between-center">
									<div>
										数量
										<span v-if="listData.fUnitName">({{listData.fUnitName}})</span>
									</div>
								</div>
								<div class="flex-row-wrap width-fill">
									<el-input-number v-model="numValue" @change="handleChange" :min="minNumValue" :max="99999" :precision="precisionNumValue"
									 :step="stepNumValue" label="描述文字"  @focus="getInputFocus($event)"></el-input-number>
								</div>
							</div>
							<div   style="width:730px"class="flex-row-space-between bg-FAFAFA address-box box-sizing padding-tb-5 padding-lr-15 margin-b-15">
								<div class="margin-b-5 font-color-000">
									{{listData.fDepotName}}仓库地址：<span class='font-color-666'>{{warehouseAddress}}</span>
								</div>
								<div class="flex-row-space-between font-color-999">
									
									<div class="pointer-color-theme" @click="toMap(warehouseAddressLatAndLng)">地图></div>
								</div>
							</div>
							<!-- 门店信息 -->
							<shopList  v-if="showShopList && (listData.fShopTypeID == 1 || isThird)" :ShopGoodID="listData.fGoodsID" :needFShopUnitID="listData.fShopUnitID" @change="shopListChange"></shopList>
              <!-- 定向送达活动 -->
              <div class="activity" v-if="isShowActivity">
                <div class="tit font-size14"> <div class="img"></div> 定向送达活动</div>
                <div class="btn" @click="showActivity()">点击参与 ></div>
                <div class="activityInfo" v-show="JSON.stringify(radioActivity) !== '{}'">在活动时间<span>{{radioActivity.activityTime}}</span>内，使用装载<span>{{radioActivity.fIsHdTitle}}</span>的车辆，将货物以<span>{{radioActivity.fDeliveryNames}}</span>的方式
				<span v-if="radioActivity.fMaxMins !=0">在<span>{{radioActivity.fMaxMins}}</span>分钟内</span>
				<span v-if="radioActivity.fMaxMins ==0">不限时间</span>
				送到<span>{{radioActivity.fFence}}</span>奖励<span>{{radioActivity.integral}}</span></div>
              </div>
							<div class="flex-row margin-b-50" v-if="listData.fGoodsClassID != '3'">
								<div v-show="fSmallMinPrice" class="btn-box background-color-theme radius30 text-center margin-r-10 pointer font-size18 font-weight700" @click="addToCart">
									加入购物车
								</div>
								<div v-show="fSmallMinPrice" class="btn-box radius30 text-center margin-r-10 bg-FF9900 font-color-fff pointer font-size18 font-weight700" @click="buyNowClick(listData)">
									立即购买
								</div>
								<!-- <div class="btn-box border-theme color-theme radius30 text-center pointer" @click="showModel = true">
									企业购
								</div> -->
							</div>
							<!-- <div class="flex-row margin-b-50" v-else>
								<div class="btn-box1 background-color-theme radius30 text-center">
									当前商品不支持销售
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>

			<div class="bottom-box font-size13 flex-row">
				<div class="b-box-left margin-r-10">
					<div class="left-top flex-colum border-F2F2F2 margin-b-10 padding-13">
						<div class="flex-row margin-b-5">
							<div class="img-box border-F2F2F2 radius10 padding-5 margin-r-5">
								<img :src="listData.fShopLogoPath" alt="" class="img-style">
							</div>
							<div class="flex-colum width-fill">
								<div class="font-weight700 font-size14 twoline-show-hide margin-b-5">{{listData.fShopName}}</div>
								<div class="shopStarRating-box radius20 flex-row bg-F2F2F2 text-center font-size12 padding-lr-10 margin-b-5">
									<span class="margin-r-5">
										店铺星级
									</span>
									<div class="margin-tb-auto">
										<el-rate v-model="starLevel" :colors="['#FF3300','#FF3300','#FF3300']" :disabled-void-color="'#e4e4e4'"
										 disabled></el-rate>
									</div>
								</div>
								<div class="flex-row-space-between-center margin-b-5">
									<div>{{focusOnQuantity}}关注</div>
									<div class="bg-F8F9FE radius30 color-theme pointer follow-box text-center" @click="followCreateClick" v-if="followShow">+关注</div>
									<div class="bg-F8F9FE radius30 color-theme pointer follow-box text-center" @click="followDeleteClick" v-else>已关注</div>
								</div>
							</div>
						</div>

						<div class="flex-row-space-between">
							<div v-if="fRulObj !=null " class="shopBtn-box text-center border-theme radius30 pointer" @click="contactServiceClick">
								<span class="iconfont icon-kefu1 margin-r-5 color-theme font-size17"></span>
								联系客服
							</div>
							<div class="shopBtn-box text-center border-theme radius30 pointer" @click="enterTheStore(listData.fShopUnitID)">
								<span class="iconfont icon-dianpu2 margin-r-5 color-theme"></span>
								进店逛逛
							</div>
						</div>
					</div>
					<!-- <div class="left-middle flex-colum border-F2F2F2 margin-b-10 padding-13" v-if="ruledetails.length>0">
						<div class="font-weight700 margin-b-10">店铺规则</div>
						<div class="padding-10 bg-fff radius5" style="height: 200px;overflow-y:scroll;">
							<div v-for="(item,index) of ruledetails" :key="index">
								<div class="flex-colum">
									<div v-if="item.fIsTitle==2" class="color-theme font-weight700" style="line-height: 22px; color: #0173FE;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==1" class="font-size12 font-weight700" style="line-height: 18px;color: #000000;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==0" class="font-size12" style="line-height: 18px;">
										{{item.fValue}}
									</div>
								</div>
							</div>
						</div>
					</div> -->
					<div class="left-bottom bg-F2F4FD box-sizing padding-13 flex-row-between-wrap" v-if="shopMerchandise.length>0">
						<div class="pointer" :class="index+1 == 1?'margin-b-10':''" v-for="(item,index) of shopMerchandise" :key="index"
						 @click="hotGoods(item)">
							<div class="img-box1 bg-fff radius10 flex-row-center-center">
								<img :src="item.goods.fPicturePath" alt="" class="img-style1 margin-auto">
							</div>
							<div class="title-box hide-text margin-t-10 font-size12">
								{{item.goods.fGoodsFullName}}
							</div>
							<div class="title-box hide-text margin-t-10 font-size14 font-color-FF0000 font-weight700">
								<span class="font-size12">￥</span>{{item.fMinGoodsPrice|NumFormat}}<span class="font-size12">元/{{listData.fUnitName}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="b-box-right">
					<div class="left-middle flex-colum border-F2F2F2 margin-b-10 padding-13" v-if="ruledetails.length>0">
						<div class="flex-row margin-b-10">
							<img src="@/assets/imgs/rules.png" alt="" style="width:28px;height:28px;">
							<div class="font-weight700  font-size18 margin-l-10" style="color:#0173FE">店铺规则</div>
							<div class="font-weight400 font-size18 margin-l-10" style="color:#0173FE">SHOP&nbsp;RULES</div>
						</div>
						<div class="padding-10 bg-fff radius5" style="box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.10); ">
							<div v-for="(item,index) of ruledetails" :key="index">
								<div class="flex-colum">
									<div v-if="item.fIsTitle==2" class="color-theme font-weight700" style="line-height: 22px; color: #0173FE;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==1" class="font-size12 font-weight700" style="line-height: 18px;color: #000000;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==0" class="font-size12" style="line-height: 18px;">
										{{item.fValue}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="">
						<el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
							<el-tab-pane v-for="(item,index) of tabData" :key="index" :label="item.fGoodsDetailClass" :name="item.fGoodsDetailClassCode">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="item.fContent != null" :html="item.fContent" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<!-- <el-tab-pane label="规格参数" name="second">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<el-tab-pane label="使用范围" name="third">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<el-tab-pane label="售后保障" name="fourth">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane> -->
							<!-- <el-tab-pane label="全部评价" name="fifth">
								<div class="flex-colum">
									<div class="evaluateTitle-box padding-lr-20 border-F2F2F2 box-sizing flex-row-align-center font-color-333">
										<div class="flex-colum margin-r-30">
											<div class="font-size12">
												好评度
											</div>
											<div class="font-size25 font-weight700 color-theme">
												{{highPraise}}
											</div>
										</div>
										<div class="font-size12 flex-row">
											<div class="whole text-center radius20 margin-r-10 bg-F1F3FE border-F1F3FE" :class="evaluateBtnData[0].checked?'border-theme color-theme':''"
											 @click="evaluateBtnClick(evaluateBtnData[0].name)">
												{{evaluateBtnData[0].name}}
											</div>
											<div class="evaluate-btn padding-lr-15 text-center radius20 margin-r-10 bg-F1F3FE border-F1F3FE" :class="item.checked?'border-theme color-theme':''"
											 v-for="(item,index) of evaluateBtnData" :key="index" @click="evaluateBtnClick(item.name)">
												{{item.name}}
											</div>
										</div>
									</div>
									<div class="padding-lr-20 margin-b-30">
										<div class="border-bottom-F2F2F2 padding-tb-10" v-for="(item,index) of evaluateListData" :key="index">
											<div class="flex-row-space-between margin-b-10">
												<div class="flex-row">
													<div class="img-box2 bg-F2F2F2 text-center margin-r-5">
														<img :src="item.img" class="img-style2">
													</div>
													<div class="flex-column">
														<span class="">
															{{item.name}}
														</span>
														<div class="margin-tb-auto">
															<el-rate v-model="value1" :colors="['#FF3300','#FF3300','#FF3300']" :disabled-void-color="'#e4e4e4'"
															 disabled></el-rate>
														</div>
													</div>
												</div>
												<div class="margin-t-auto font-color-999">
													{{item.date}}
												</div>
											</div>
											<div class="flex-row-space-between font-color-999">
												<div class="">
													<span class="margin-r-10">
														{{item.wuliao}}
													</span>
													{{item.num}}吨
												</div>
												<div>
													<span class="margin-r-5" :class="thumbActive?'color-theme':''">{{item.num}}</span>
													<span class="iconfont icon-dianzan" :class="thumbActive?'color-theme':''" @click="thumbClick"></span>
												</div>
											</div>
										</div>
									</div>
									<div class="flex-row-center-flex-end">
										<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
										 :pagesize="pagesize" :total="total">
										</pagination>
									</div>
								</div>
							</el-tab-pane> -->
						</el-tabs>
					</div>
				</div>
			</div>
		</div>

		<el-dialog v-dialogDrag title="议价申请" :visible.sync="showModel" width="30%" :before-close="handleClose"
		 :close-on-click-modal="false" :close-on-press-escape="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="form" :rules="rules" :model="form" :label-width="formLabelWidth" label-position="right">
					<el-form-item label="询价单号">
						<el-input v-model="form.danhao" placeholder="请填写询价单号" />
					</el-form-item>
					<el-form-item label="价格开始日期">
						<el-date-picker type="date" placeholder="请选择价格开始日期" v-model="form.startDate" style="width: 100%;"></el-date-picker>
					</el-form-item>
					<el-form-item label="使用地点" prop="didian">
						<el-input v-model="form.didian" placeholder="请填写使用地点" />
					</el-form-item>
					<el-form-item label="原因" prop="yuanyin">
						<el-input v-model="form.yuanyin" placeholder="请填写原因" />
					</el-form-item>
					<el-form-item label="备注" prop="fRemark">
						<el-input v-model="form.fRemark" placeholder="请填写备注" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>

    <el-dialog
      title="定向送达活动"
      :visible.sync="isShowActivityDailog"
      :close-on-click-modal="false"
      width="60%">
	  <div   class="margin-l-10" >
	    <span>定向送达活动中</span>
	    <span class="OrderText">使用GPS设备的车辆在抵达目的地后需上传车辆信息</span>
	    <span>，未上传信息的派车单将无法赠送积分(使用汉德设备无需上传)。</span>
	    <a style="color:#0173FE;OrderA" target="_blank" href="https://www.xjjcjc.com/helpcenter/FenceArrive.html">(点击查看GPS设备车辆上传操作流程)</a>
	  </div>
      <el-table
        :header-cell-style="{background:'#F8F9FE'}"
        class="margin-b-10 margin-t-10 margin-lr-15"
        :data="tableData"
        size="mini"
        border>
        <el-table-column
          label="选择"
          align="center"
          fixed
          width="55">
          <template slot-scope="scope">
            <el-radio v-model="radioIdSelect" :label="scope.row.fRuleID" @change.native="handleSelectionChange(scope.row)" @click.native="clickRadio($event, scope.row)">{{str}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          label="送达目的地"
          fixed
          width="150">
          <template slot-scope="scope">
            <el-button type="text" class="fence" @click="showFencveInfo(scope.row)">{{scope.row.fFence}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="fDeliveryNames"
          label="提货方式"
          width="160">
        </el-table-column>
        <el-table-column
          align="center"
          label="运输时长(分钟)"
          width="200">
          <template slot-scope="scope">
            <template v-if="scope.row.fMaxMins !=0">
          	<span>{{scope.row.fMaxMins}}</span>
          		<hr>
          		<span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
          	</template>
          	<template v-if="scope.row.fMaxMins ==0">
          		<span>不限时间</span>
          	</template		>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="活动时间">
          <template slot-scope="scope">
            <span>{{scope.row.fBeginTime}}</span>
                <hr>
                <span>至</span>
                <hr>
                <span>{{scope.row.fEndTime}}</span>
            <hr>
            <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 以送达时间为准</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="integral"
          label="赠送积分"
          width="120">
        </el-table-column>
		<el-table-column
		  align="center"
		  prop="fIsHdName"
		  label="设备"
		  width="100">
		</el-table-column>
        <el-table-column
          label=""
          width="160"
          align="center">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showFencveInfo(scope.row)"
              type="primary"
              size="small">
              查看目的地范围
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uncheckedActivity">取消选中</el-button>
        <el-button type="primary" @click="checkedActivity">确认选中</el-button>
      </span>
    </el-dialog>
   <myMapType v-if="myMapTypeVisible" :showModel="myMapTypeVisible" :myMapTypeID="myMapTypeID"  @change="myMapTypeChange"></myMapType>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	import countDowm from '@/components/countDown/countDown.vue'
	import RichTextStyle from "@/components/richtext/RichTextStyle";
	import pagination from '@/components/pagination/pagination.vue'
	import selectArea from '@/components/selectArea/selectArea.vue'
	import config from '../../../../config/config.json';
    import myMapType from '@/components/myMapType.vue';
	import shopList from '@/components/shoplist/shopList.vue' // 门店信息
	import piczoom from '@/components/vue-piczoom/'
	export default {
		components: {
			breadcrumb,
			countDowm,
			RichTextStyle,
			pagination,
			selectArea,
			myMapType,
			shopList,
			piczoom
		},
		data() {
			return {
		isThird: false, // 第三方店铺进入不显示门店
		showShopList:false, // 是否显示门店
		myMapTypeVisible:false, //控制地图显示弹窗的显示与隐藏
		myMapTypeID:"",		
        // 定向送达活动奖励数据
        isShowActivityDailog: false,
        isShowActivity: false,
        getActivity: {},
        str: '',
        radioIdSelect: -1,
        fDeliveryIDs: '', // 提货方式
        activeActivity: {},
        tableData: [],
        map: {},
        showFence: false,
        district: '',
        opts: {
          subdistrict: 0, // 获取边界不需要返回下级行政区
          extensions: 'all', // 返回行政区边界坐标组等具体信息
          level: 'district' // 查询行政级别为 市
        },
        showbiz: false,
        polygonData: [],
        fUnitNames: '',
        radioActivity: {},


				changeStyle:'flex-row-space-between',
				videoShow: false, //商品视频介绍是否展示
				isCollection: true, //控制收藏点击
				popoverShow: false, //配送地Popover 弹出框是否弹出
				deliveryAddress: '', //配送地址

				collectCouponsPopupData: [], //优惠券信息
				addressActiveName: 'first', //默认显示哪个tab
				addressList: [], //地址数据

				fGoodsID: 1, //当前商品id
				fDeliveryID: '', //当前商品选中的提货方式id
				fGovernmenAreaID: ['', '', '', ''], //当前区域id
				numValue: null, //商品数量
				minNumValue: null, //计数器可输入的最小值
				stepNumValue: null, //计数器步长
				precisionNumValue: null, //计数器精度
				showPicUrl: {
					fPicturePath: ''
				}, //商品主图
				picurl: [], //商品左右图片
				showPicInx: 0, // 当前选中商品图片下标
				priceRange: '', //价格区间
				listData: {}, //商品详情数据
				collection: false, //是否收藏
				evaluateNum: '100+',
				highPraise: '90%',
				evaluateListData: [{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					}
				],
				countDownShow: true,
				saleAttributeList: [], //商品销售属性

				otherAttributeList: [], //商品其他销售属性

				deliveryMethodList: [], //提货方式

				warehouseAddress: '', //仓库地址
				warehouseAddressLatAndLng: {}, //仓库所有信息包含仓库地址经纬度

				attributeGoods: [], //判断商品是否存在如不存在则显示无货接口除了其他属性以外属性参数数组
				otherattributeID: '', //判断商品是否存在如不存在则显示无货其他属性参数
				isItAvailable: true, //是否有货

				focusOnQuantity: '', //店铺多少人关注
				followShow: false, //是否关注

				ismakeIntegral: '', //店铺产不产生积分
				// shopRulesData: [{
				// 		title: '积分规则',
				// 		outerList: [{
				// 				listTitle: '积分获取：',
				// 				list: [{
				// 						rule: '首次关注店铺送X积分；'
				// 					}, {
				// 						rule: '任务送积分（详情见任务集市）；'
				// 					}
				// 					// , {
				// 					// 	rule: '每日首次订单分享送X积分；'
				// 					// }, {
				// 					// 	rule: '每日首次任务分享送X积分；'
				// 					// }
				// 					, {
				// 						rule: '每笔订单评价送X积分；'
				// 					}, {
				// 						rule: '提货出厂送积分，该笔提货对应的货款金额每满100元送1积分（如有积分活动，详见店铺通知）；'
				// 					}, {
				// 						rule: '其它赠送积分。'
				// 					}
				// 				]
				// 			},
				// 			{
				// 				listTitle: '积分使用：',
				// 				list: [{
				// 					rule: '购买本店铺商品使用；'
				// 				}, {
				// 					rule: '兑换平台金币使用；'
				// 				}, {
				// 					rule: '店铺其它活动使用。'
				// 				}]
				// 			}
				// 		]
				// 	},
				// 	{
				// 		title: '订单有效期',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 				rule: '本店铺订单有效期为自生效之日起X个自然日。'
				// 			}]
				// 		}]
				// 	},
				// 	{
				// 		title: '发票',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '本店铺每月扎账时间为XX日XX点；'
				// 				},
				// 				{
				// 					rule: '本店铺发票开具时间为每月XX号统一开具；'
				// 				},
				// 				{
				// 					rule: '发票递送方式：⑴、客户自取。⑵、发票开具后X个自然日内邮寄。⑶、电子发票开具后发送至预留邮箱。'
				// 				}
				// 			]
				// 		}]
				// 	},
				// 	{
				// 		title: '检验报告单',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '本店铺提供：电子检验报告单/纸质检验报告单；'
				// 				},
				// 				{
				// 					rule: '电子检验报告单由会员在本平台自行申请获取；'
				// 				},
				// 				{
				// 					rule: '纸质检验报告单需会员与本店铺客服联系获取。'
				// 				}
				// 			]
				// 		}]
				// 	},
				// 	{
				// 		title: '售后',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '售后服务电话：XXXX-XXXXXXX/1XXXXXXXXXX'
				// 				},
				// 				{
				// 					rule: '售后服务时间：工作日/每日  XX:XX-XX:XX'
				// 				}
				// 			]
				// 		}]
				// 	},
				// ], //店铺规则

				starLevel: null, //店铺星级
				shopMerchandise: [], //店铺热销商品

				tabData: [], //厂家，规格，适用范围，售后保障
				activeName: '', //选中哪个
				content: '',
				rule: [], //富文本规则
				thumbActive: false, //评价是否点赞

				total: 1000, //总条数
				pagesize: 10, //每页显示条目个数
				currentPage: 1, //当前页

				showModel: false, //打开议价申请
				form: {
					danhao: '',
					startDate: '',
					didian: '',
					yuanyin: '',
					fRemark: ''
				},
				rules: {
					didian: [{
						required: true,
						message: '地点不能为空',
						trigger: 'blur'
					}],
				},
				formLabelWidth: '100px', //label宽
				evaluateBtnData: [{
						name: '全部',
						checked: true
					},
					{
						name: '好评60+',
						checked: false
					},
					{
						name: '中评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					}
				],
				hasActivity: false, //是否有活动
				hasShowAl: false, //是否有活动
				activityInfo: {}, //活动内容
				usedTime: 0, //倒计时的毫秒数
				showactivitytext: "",
				fIsActivity: 0, //是否参与活动0未参与1参与
				couponShow: false,
				fIsEnablePoint: 0, //是否参与积分活动 0未参与 1参与
				fSmallMinPrice:'',
				ruledetails:[],
				acsData:{},
				acsDataimmediately:{},
				fRulObj:null,
				CheckGoodsShow:0,
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		// activated() {
		// 	this.$refs.piclist.style.left = 48 + "px";
		// 	////console.log('this.area', this.area);
		// 	this.fGoodsID = this.$route.query.fGoodsID;
		// 	this.getGoodsBaseInfo();
		// 	this.getCouponInfo();
		// },
		mounted() {
			this.$store.commit("changeGoodsDetailsIsGoodsData",this.$route.query.detailobj);
			this.$refs.piclist.style.left = 48 + "px";
			//console.log('this.area', this.area);
			this.fGoodsID = this.$route.query.fGoodsID;
			this.getGoodsBaseInfo();
			this.getCouponInfo();
			this.getGoodsActivityInfo();
			this.getCheckGoodsid()
		},
		methods: {
			
		getCheckGoodsid(){
			 this.ApiRequestPostNOMess('api/mall/ebactivity/deliver-task/check-goods-id',{
				 id:this.fGoodsID
			 }).then(res=>{
				 console.log(res)
				 this.CheckGoodsShow=res
			 })	
		},
		toTask(){
			this.$router.push('/Task')
		},
		//门店组件的返回事件
		shopListChange(val1,val2){
			this.isThird = true
			//当门店变化后，需要再次调用接口！！！
			if(val1){
				this.fGoodsID = val2
				this.getCouponInfo() // 优惠券
				this.getGoodsBaseInfo() // 商品信息  定向送达在这个请求里面
				this.getGoodsActivityInfo() // 活动
				this.getCheckGoodsid()
			}
		},
      // 显示显示活动奖励对话框
      clickRadio(e, row) {
        if(e.target.tagName != 'INPUT'){
          return
      }
        if (this.radioIdSelect === row.fRuleID) {
          this.radioIdSelect = -1
          this.fDeliveryIDs = ''
          this.activeActivity = {}
        }
      },
      // 确认选中
      checkedActivity() {
        if (this.radioIdSelect === -1) {
          this.$message.warning('请选择一个活动奖励')
          return
        }
        this.radioActivity = this.activeActivity
		if(this.radioActivity.fIsHd ==0){
			this.radioActivity.fIsHdTitle="GPS设备"
		}else if(this.radioActivity.fIsHd ==1){
			this.radioActivity.fIsHdTitle="汉德设备"
		}
		console.log(this.radioActivity)
        this.isShowActivityDailog = false
      },
      // 取消选中
      uncheckedActivity() {
        this.radioIdSelect = -1
        this.radioActivity = this.activeActivity = {}
        this.isShowActivityDailog = false
      },
      showActivity() {
        this.isShowActivityDailog = true
      },
      // 获取限时奖励信息
      getActivityInfo() {
        this.ApiRequestPostNOMess('api/mall/ebactivity/limited-delivery-rule/get-limited-delivery-rule-B2C-commodity', this.getActivity).then(res => {
          if (res.obj.length == 0) {
            this.isShowActivity = false
            return
          } else {
            console.log(this.fUnitNames)

            this.isShowActivity = true
            this.tableData = res.obj
            this.tableData.forEach((item, index) => {
              item.activityTime = this.getTime(res.obj[index].fBeginTime) + '至' + this.getTime(res.obj[index].fEndTime)
              item.fBeginTime = this.getTime(item.fBeginTime) 
              item.fEndTime =  this.getTime(item.fEndTime)
              item.integral = res.obj[index].fPointAmount + '积分/' + this.fUnitNames
			  if(item.fIsHd ==1){
				  item.fIsHdName='汉德设备'
			  }else if(item.fIsHd ==0){
				   item.fIsHdName='GPS设备'
			  }
            })
          }
        })
      },
      handleSelectionChange(val) {
        this.activeActivity = val
        this.fDeliveryIDs = val.fDeliveryIDs
      },
	  // 新增弹窗提交成功回调
	  myMapTypeChange(val) {
	    this.myMapTypeVisible = val;
	  },
	  // 显示地图
	  showFencveInfo(row) {
	    this.myMapTypeID= row.fAreaID
	    this.myMapTypeVisible=true;
	  },
			//获取优惠券
			getCouponInfo() {
				let _this = this;
				// setTimeout(function() {
				// 	_this.loading = false;
				// }, 10000);
				this.ApiRequestPostNOMess('api/mall/ebactivity/coupon-rule-bill/get-couponrule-bill-by-goodsid', {
					id: this.fGoodsID
				}, ).then(res => {
					console.log('获取优惠券', res);
					if (res.obj && res.obj.length > 0) {
						//就说明有优惠券  他就可以通过领券中心入口区领券中心领取
						let now = new Date().getTime();
						let end = new Date(res.obj[0].fEndSendTime).getTime(); //结束时间
						//优惠券的结束领取时间，必须大于现在时间，否则视为过期，那就没法领了
						if(end > now){
							this.couponShow = true;
							this.changeStyle = 'flex-row-space-between'
						}
					} else {
						//就说明没有有优惠券  去领券中心领取得入口就消失
						this.couponShow = false;
					}
					if(this.couponShow == false){
						this.changeStyle = 'anotherStyle'
					}
				}, error => {

				});
			},
			//领券弹窗里面按钮点击事件fReceiveStatus==0调用领取优惠券接口==1查看可购买商品
			receiveClick(fReceiveStatus, fCouponID) {
				if (fReceiveStatus == 0) {
					this.ApiRequestPostNOMess('api/mall/ebactivity/customerreceiverecord/create', {
						fCouponID: fCouponID
					}, ).then(res => {
						//console.log('领取优惠券', res);
						this.getCouponInfo();
					}, error => {

					});
				}
			},
			//小图左边三角点击事件
			leftImg() {
				if (this.showPicInx > 0 && this.showPicInx <= this.picurl.length) {
					this.showPicInx = this.showPicInx - 1;
					this.showPicUrl = this.picurl[this.showPicInx];
					if (
						this.showPicInx == 0 ||
						this.showPicInx == 1 ||
						this.showPicInx == 2 ||
						this.showPicInx == 3
					) {
						this.$refs.piclist.style.left = 38 + "px";
					} else {
						this.$refs.piclist.style.left =
							"-" + 125 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//小图右边三角点击事件
			rightImg() {
				if (this.showPicInx >= 0 && this.showPicInx < this.picurl.length - 1) {
					this.showPicInx = this.showPicInx + 1;
					this.showPicUrl = this.picurl[this.showPicInx];
					if (this.showPicInx == 0 || this.showPicInx == 1 || this.showPicInx == 2 || this.showPicInx == 3) {
						this.$refs.piclist.style.left = 20 + "px";
					} else {
						//console.log('11');
						this.$refs.piclist.style.left =
							"-" + 130 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//图片点击事件
			imgClick(val, index) {
				this.showPicUrl = val;
				this.showPicInx = index;
			},
			//倒计时结束事件
			countDownEnd(val, index) {
				this.getGoodsActivityInfo()
			},


			//根据商品ID查询当前商品信息
			getGoodsBaseInfo() {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get-goods-base-info', {
						id: this.fGoodsID
					})
					.then(result => {
							this.showShopList = true
							this.videoShow = false;
							console.log('商品信息', result);
							this.picurl = [];
							this.listData = result.obj;
							if(this.listData.fStatus  == 2){
								this.hasActivity = false
								this.hasShowAl = false;
							}
							// 店铺星级
							let sums = result.obj.fEvaluationScore;
							if (sums != null) {
								this.starLevel = sums / 20;
							}
							this.fIsEnablePoint = result.obj.fIsEnablePoint; //是否参与积分活动
							// this.showPicUrl = this.getUploadFileURL(result.obj.fPicturePath);
							this.showPicUrl.fPicturePath = result.obj.fPicturePath;
							result.obj.goodsPictures.unshift(this.showPicUrl);
							this.picurl = result.obj.goodsPictures;
							//console.log('this.showPicUrl', this.showPicUrl);
							//console.log('this.picurl', this.picurl);
							this.getAddress();

							this.getIsmakeIntegral(); //获取本店铺是否产生积分
							// this.getFirstFocus(); //获取首次关注店铺送多少积分
							// this.getFristOrderShard(); //获取每日首次订单分享送多少积分
							// this.getEverydayFristShard(); //获取每日首次任务分享送多少积分
							// this.getEverydayEvaluation(); //获取每笔订单评价送多少积分
							this.getIntegral(); //获取首次关注店铺送多少积分/获取每笔订单评价送多少积分
							this.getTheNaturalDay(); //获取本店铺订单有效期
							this.getShopSettleDay(); //获取店铺扎帐日
							this.getShopMakeOpenBill(); //获取本店铺发票开具时间为每月多少号
							this.getShopMakeOpenBillPostday(); //获取店铺发票开具后多少个自然日内邮寄
							this.getAfterSalesserviceTelephone(); //获取售后服务电话
							this.getAfterSalesserviceTime(); //获取售后服务时间

							this.getCustomerInfo();
							this.getGoodidPrice(); //根据商品ID查询当前商品价格区间
							this.getStartStatus();
							this.getSaleAttributeList();
							this.getHotGoodsByFShopUnitIDList();
							this.getByGoodsidList();
							this.getByShopunitID(); //获取是否关注店铺
							this.storeRules()
                            this.getByShop()
              this.getActivity.fGoods = result.obj.fGoodsID
              this.getActivity.fDepotID = result.obj.fDepotID
              this.fUnitNames = result.obj.fUnitName
              this.getActivityInfo() // 获取显示送达活动奖励
							this.otherAttributeList = [];
							for (let item of result.obj.goodsClassProperties) {
								if (item.fIsSeftDefine == 1) {
									let data = {
										fClassPropertie: item.fClassPropertie,
										fClassPropertieID: item.fClassPropertieID,
										detailed: []
									}
									for (let pitem of result.obj.goodsPropertieDataDtos) {
										if (pitem.fClassPropertieID == item.fClassPropertieID) {
											let detailed = {
												checked: false,
												fGoodsPropertieData: pitem.fGoodsPropertieData,
												fGoodsPropertieDataID: pitem.fGoodsPropertieDataID
											}
											data.detailed.push(detailed)
										}
									}
									if (data.detailed.length > 0) {
										data.detailed[0].checked = true;
										this.otherAttributeList.push(data);
									}
								}
							}
							console.log(this.otherAttributeList, '其他属性');
							
						},
						rej => {}
					)
			},

			//店铺规则
			storeRules(){
				this.ApiRequestPostNOMess('api/mall/ebshop/baseinfo/get-rule-by-shopid',{
					id: this.listData.fShopUnitID
				}).then(
				  res => {
					  this.ruledetails = res.obj
					  console.log(777777777777,this.rule)
				  }
				)
			},
			//获取本店铺是否产生积分
			getIsmakeIntegral() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-ismake-integral', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取本店铺是否产生积分', res);
					this.ismakeIntegral = res ? '产生' : '不产生';
				}, error => {

				});
			},
			//获取首次关注店铺送多少积分/获取每笔订单评价送多少积分
			getIntegral() {
				this.ApiRequestPostNOMess('api/mall/ebactivity/first-focus-shop-rule/get-shop-rule', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取首次关注店铺送多少积分', res.obj.firstFocusShopRule);
					console.log('获取每笔订单评价送多少积分', res.obj.fAppraiseOrderShopRule);
					// this.shopRulesData[0].outerList[0].list[0].rule = '首次关注店铺送' + res.obj.firstFocusShopRule + '积分；';
					// this.shopRulesData[0].outerList[0].list[2].rule = '每笔订单评价送' + res.obj.fAppraiseOrderShopRule + '积分；';
				}, error => {

				});
			},
			//获取首次关注店铺送多少积分
			// getFirstFocus() {
			// 	this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-first-focus', {
			// 		fShopID: this.listData.fShopUnitID
			// 	}).then(res => {
			// 		console.log('获取首次关注店铺送多少积分', res);
			// 		this.shopRulesData[0].outerList[0].list[0].rule = '首次关注店铺送' + res + '积分；';
			// 	}, error => {

			// 	});
			// },
			//获取每日首次订单分享送多少积分
			getFristOrderShard() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-frist-order-shard', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取每日首次订单分享送多少积分', res);
					// this.shopRulesData[0].outerList[0].list[2].rule = '每日首次订单分享送' + res + '积分；';
				}, error => {

				});
			},
			//获取每日首次任务分享送多少积分
			getEverydayFristShard() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-everyday-first-shard', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取每日首次任务分享送多少积分', res);
					// this.shopRulesData[0].outerList[0].list[3].rule = '每日首次任务分享送' + res + '积分；';
				}, error => {});
			},
			//获取每笔订单评价送多少积分
			// getEverydayEvaluation() {
			// 	this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-everyorder-evaluation', {
			// 		fShopID: this.listData.fShopUnitID
			// 	}).then(res => {
			// 		console.log('获取每笔订单评价送多少积分', res);
			// 		this.shopRulesData[0].outerList[0].list[2].rule = '每笔订单评价送' + res + '积分；';
			// 	}, error => {

			// 	});
			// },
			//获取本店铺订单有效期
			getTheNaturalDay() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-the-natural-day', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取本店铺订单有效期', res);
					// this.shopRulesData[1].outerList[0].list[0].rule = '本店铺订单有效期为自生效之日起' + res + '个自然日。';
				}, error => {

				});
			},
			//获取店铺扎帐日
			getShopSettleDay() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-settle-day', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取店铺扎帐日', res);
					this.getShopSettleTime(res);
				}, error => {

				});
			},
			//获取店铺扎帐时间
			getShopSettleTime(day) {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-settle-time', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取店铺扎帐时间', res);
					// this.shopRulesData[2].outerList[0].list[0].rule = '本店铺每月扎账时间为' + day + '日' + res + '点；';
				}, error => {

				});
			},
			//获取本店铺发票开具时间为每月多少号
			getShopMakeOpenBill() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-make-open-bill', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取本店铺发票开具时间为每月多少号', res);
					// this.shopRulesData[2].outerList[0].list[1].rule = '本店铺发票开具时间为每月' + res + '号统一开具；';
				}, error => {

				});
			},
			//获取店铺发票开具后多少个自然日内邮寄
			getShopMakeOpenBillPostday() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-make-open-bill-postday', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取店铺发票开具后多少个自然日内邮寄', res);
					// this.shopRulesData[2].outerList[0].list[2].rule = '发票递送方式：⑴、客户自取。⑵、发票开具后' + res +
						'个自然日内邮寄。⑶、电子发票开具后发送至预留邮箱。';
				}, error => {

				});
			},
			//获取售后服务电话
			getAfterSalesserviceTelephone() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-after-salesservice-telephone', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取售后服务电话', res);
					// this.shopRulesData[4].outerList[0].list[0].rule = '售后服务电话：' + res;
				}, error => {

				});
			},
			//获取售后服务时间
			getAfterSalesserviceTime() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-after-salesservice-time', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取售后服务时间', res);
					// this.shopRulesData[4].outerList[0].list[1].rule = '售后服务时间：工作日/每日  ' + res;
				}, error => {

				});
			},
			//店铺规则结束


			//获取默认收货地址(如果没有默认地址拿他的行政区域)
			getCustomerInfo() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-customer-info', {})
					.then(result => {
							if (result.obj.isHaveDefaultAddress == 1) {
								result.obj.addresslist.forEach((item, index) => {
									item.fFullPathAddress = item.fFullPathAddress.replace(/\\/g, '');
									if (item.fIsDefault == 1) {
										this.fGovernmenAreaID[0] = item.fGovernmenAreaID.substring(0, 2);
										this.fGovernmenAreaID[1] = item.fGovernmenAreaID.substring(0, 4);
										this.fGovernmenAreaID[2] = item.fGovernmenAreaID.substring(0, 6);
										this.fGovernmenAreaID[3] = item.fGovernmenAreaID;
										this.deliveryAddress = item.fFullPathAddress;
										item.checked = true;
									} else {
										item.checked = false;
									}
								});
								this.addressList = result.obj.addresslist;
							} else {
								result.obj.fGovernmenArea = result.obj.fGovernmenArea.replace(/\\/g, '');
								this.fGovernmenAreaID[0] = result.obj.fGovernmenAreaID.substring(0, 2);
								this.fGovernmenAreaID[1] = result.obj.fGovernmenAreaID.substring(0, 4);
								this.fGovernmenAreaID[2] = result.obj.fGovernmenAreaID.substring(0, 6);
								this.fGovernmenAreaID[3] = result.obj.fGovernmenAreaID;
								this.deliveryAddress = result.obj.fGovernmenArea;
								let data = [{
									fFullPathAddress: result.obj.fGovernmenArea,
									fGovernmenAreaID: result.obj.fGovernmenAreaID
								}]
								this.addressList = data;
							}
							this.getDeliveryByAreaid();
							//console.log('this.fGovernmenAreaID', this.fGovernmenAreaID);
							//console.log('获取默认收货地址(如果没有默认地址拿他的行政区域)', result);
							// this.fGovernmenAreaID[0] = result.obj.fGovernmenAreaID.substring(0, 2);
							// this.fGovernmenAreaID[1] = result.obj.fGovernmenAreaID.substring(0, 4);
							// this.fGovernmenAreaID[2] = result.obj.fGovernmenAreaID.substring(0, 6);
							// this.fGovernmenAreaID[3] = result.obj.fGovernmenAreaID;
						},
						rej => {}
					)
			},
			//地址选择事件
			addressClick(obj) {
				this.addressList.forEach((item, index) => {
					if (obj.fGovernmenAreaID == item.fGovernmenAreaID) {
						console.log('obj', obj);
						this.fGovernmenAreaID[0] = item.fGovernmenAreaID.substring(0, 2);
						this.fGovernmenAreaID[1] = item.fGovernmenAreaID.substring(0, 4);
						this.fGovernmenAreaID[2] = item.fGovernmenAreaID.substring(0, 6);
						this.fGovernmenAreaID[3] = item.fGovernmenAreaID;
						this.deliveryAddress = obj.fFullPathAddress;
						item.checked = true;
					} else {
						item.checked = false;
					}
				});
				this.popoverShow = false;
				this.getDeliveryByAreaid();
			},
			//行政区域选择事件
			ChooseArea(arrid, address) {
				// console.log('arrid', arrid);
				// console.log('address', address);
				this.fGovernmenAreaID = arrid;
				this.popoverShow = false;
				this.deliveryAddress = address.join('');
				this.getDeliveryByAreaid();
			},

			//如果当前配送地无商品则显示商品的最低价格
			getMinGoodidPrice() {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-min-goodsprice', {
						id: this.fGoodsID
					})
					.then(res => {
							//console.log('如果当前配送地无商品则显示商品的最低价格', res);
							this.priceRange = res.obj.goodsPrice.fGoodsPrice;
							this.numValue = res.obj.goodsSaleAttribute.fMinSaleAmount; //最小销量
							this.minNumValue = res.obj.goodsSaleAttribute.fMinSaleAmount; //最小销量
							this.stepNumValue = res.obj.goodsSaleAttribute.fAdjustmentRange; //调整幅度
							this.precisionNumValue = res.obj.goodsSaleAttribute.fPrecision; //精度
						},
						rej => {}
					)
			},
			//根据商品ID查询当前商品价格
			getGoodidPrice() {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-list-goods-price-by-government-area-id', {
						goodsID: this.fGoodsID,
						fGovernmenAreaID: -1, //不根据区域定价，区域id传-1
						// areaID: '652303200',
						deliveryId: -1
					})
					.then(result => {
							//console.log('商品价格', result);
							if (result.obj.length > 0) {
								let data = result.obj[0];
								console.log(result,'这就是价格')
								this.priceRange = data.fGoodsPrice;
								this.fSmallMinPrice = data.fGoodsMinPrice;
								console.log(this.priceRange)
								console.log(this.fSmallMinPrice)
								this.numValue = data.fMinSaleAmount; //最小销量
								this.minNumValue = data.fMinSaleAmount; //最小销量
								this.stepNumValue = data.fAdjustmentRange; //调整幅度
								this.precisionNumValue = data.fPrecision; //精度
							} else {
								this.isItAvailable = false;
								// this.getMinGoodidPrice();
							}
						},
						rej => {}
					)
			},
			//获取厂家，规格，适用范围，售后保障
			getByGoodsidList() {
				this.ApiRequestPostNOMess('api/mall/goods/goods-detail/get-by-goodsid-list', {
						goodsID: this.fGoodsID,
						fAppType: 1
					})
					.then(result => {
							//console.log('厂家，规格，适用范围，售后保障', result);
							if (result.obj.length <= 0) {

							} else {

								result.obj.forEach((item, i) => {
									if (i == 0) {
										item.checked = true;
										this.content = item.fContent;
									} else {
										item.checked = false;
									}
								});
								console.log('获取厂家等等数据', result.obj)
								this.tabData = result.obj;
								this.activeName = this.tabData[0].fGoodsDetailClassCode;
							}
						},
						rej => {}
					)
			},
			//获取当前商品是否收藏
			getStartStatus() {
				this.ApiRequestPost('api/mall/auxiliary/goods-collect/get-start-status', {
						id: this.fGoodsID
					})
					.then(result => {
							// //console.log('获取当前商品是否收藏', res);
							this.collection = result.obj;
						},
						rej => {}
					)
			},
			//去领券中心
			toCouponCollection() {
        if(this.activityInfo.fShopUnitID =='-1') {
				this.$router.push('/CouponCollection')
        }else{
        this.$router.push({
        name: 'CouponCollectionShop',
        query: { id: this.listData.fShopUnitID }
      });
    }
			},
			//用户可对商品进行收藏和取消收藏
			star() {
				// 防止用户频繁点击导致的问题
				// var flag=true
				if (this.isCollection) {
					this.isCollection = false
					this.ApiRequestPostNOMess('api/mall/auxiliary/goods-collect/star', {
							id: this.fGoodsID
						})
						.then(result => {
								// //console.log('用户可对商品进行收藏和取消收藏', res);
								this.getStartStatus();
								setTimeout(() => {
									this.isCollection = true
								}, 1000)
								// }
							},
							rej => {}
						)
				}
			},
			//根据商品详情ID查询相同关键属性商品的销售属性集
			getSaleAttributeList() {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get-sale-attribute-list', {
						id: this.fGoodsID
					})
					.then(result => {
							//console.log('根据商品详情ID查询相同关键属性商品的销售属性集', result);
							let Arr = [];
							let ArrID = [];
							for (let item of result.obj) {
								for (let childitem of item.goodsClassPropertieDatas) {
									for (let pitem of this.listData.fPropertieDataIDS.split(',')) {
										if (pitem == childitem.fClassPropertieDataID) {
											childitem.checked = true;
											Arr.push(childitem.fClassPropertieData);
											ArrID.push(childitem.fClassPropertieDataID);
										}
									}
								}
							}
							// this.selectedData = Arr.toString();
							this.saleAttributeList = result.obj;
							this.attributeGoods = ArrID; //判断商品是否存在如不存在则显示无货接口除了其他属性以外属性参数数组
						},
						rej => {}
					)
			},
			//生产厂点击事件
			saleAttributeClick(val, val1) {
				for (let item of this.saleAttributeList) {
					if (item.fClassPropertieID == val) {
						for (let childitem of item.goodsClassPropertieDatas) {
							// //console.log('222');
							if (childitem.fClassPropertieDataID == val1) {
								// //console.log('333');
								childitem.checked = true;
							} else {
								childitem.checked = false;
							}
						}
					}
				}
				this.$forceUpdate();
				let attributes = [];
				for (let item of this.saleAttributeList) {
					for (let childitem of item.goodsClassPropertieDatas) {
						// //console.log('222');
						if (childitem.checked) {
							// //console.log('333');
							attributes.push(childitem.fClassPropertieDataID);
						}
					}
				}
				this.getAttributeGoods(attributes);
			},

			//根据包装方式查商品变了没
			getAttributeGoods(attributes) {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get-attribute-goods', {
						fGoodsID: this.fGoodsID,
						attributes: attributes
					})
					.then(result => {
							this.fGoodsID = result.obj.fGoodsID;
							this.getCouponInfo();
							this.getGoodsBaseInfo();
							this.getGoodsActivityInfo();
							this.getCheckGoodsid()
						},
						rej => {}
					)
			},


			//根据商品ID查询同类商品的其他属性
			getGoodsOtherAttributeList() {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get-goods-other-attribute-list', {
						id: this.fGoodsID
					})
					.then(result => {
							console.log('根据商品ID查询同类商品的其他属性', result);
							let Arr = [];
							let otherattributeID = '';
							for (let item of result.obj) {
								console.log('111', this.listData.goodsPropertieDataDtos);
								for (let pitem of this.listData.goodsPropertieDataDtos) {
									if (pitem.fGoodsPropertieDataID == item.fClassPropertieDataID) {
										item.checked = true;
										Arr.push(item.fClassPropertieData);
										otherattributeID = item.fClassPropertieDataID;
									} else {
										item.checked = false;
									}
								}
							}
							// this.selectedData += Arr.toString();
							this.otherattributeID = otherattributeID; //判断商品是否存在如不存在则显示无货其他属性参数
							this.otherAttributeList = result.obj;
						},
						rej => {}
					)
			},
			//其他属性点击事件
			otherAttributeClick(val, val1) {
				console.log(val, val1);
				// let otherattributeID = '';
				for (let item of this.otherAttributeList) {
					if (item.fClassPropertieID == val) {
						item.detailed.forEach(item1 => {
							if (item1.fGoodsPropertieDataID == val1) {
								item1.checked = true;
							} else {
								item1.checked = false;
							}
						})
					}
				}
				this.$forceUpdate();
				// this.otherattributeID = otherattributeID; //判断商品是否存在如不存在则显示无货其他属性参数
			},


			//获取店铺热销商品
			getHotGoodsByFShopUnitIDList() {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-hot-goods-by-fShopUnitID-list', {
						id: this.listData.fShopUnitID
					})
					.then(result => {
							console.log('获取店铺热销商品', result);
							// for (let item of result.obj) {
							//   item.goods.fPicturePath = this.getUploadFileURL(item.goods.fPicturePath);
							// }
							if (result.obj.length <= 4) {
								this.shopMerchandise = result.obj;
							} else {
								this.shopMerchandise = result.obj.slice(0, 4);
							}
						},
						rej => {}
					)
			},


			//根据商品ID查询当前商品提货方式
			getDeliveryByAreaid() {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-list-goods-delivery-by-government-area-id', {
						goodsID: this.fGoodsID,
						fGovernmenAreaID: this.fGovernmenAreaID[3],
						// areaID: '652303200'
					})
					.then(result => {
							//console.log('商品提货方式', result);
							if (result.obj.length > 0) {
								this.isItAvailable = true;
								result.obj.forEach((item, index) => {
									if (index == 0) {
										item.checked = true;
									} else {
										item.checked = false;
									}
								});
								this.deliveryMethodList = result.obj;
								this.fDeliveryID = result.obj[0].fDeliveryID;
								// this.getGoodidPrice(); //根据商品ID查询当前商品价格区间
							} else {
								this.isItAvailable = false;
								// this.getMinGoodidPrice();
							}
						},
						rej => {}
					)
			},
			//提货方式点击事件
			deliveryMethodClick(val) {
				for (let item of this.deliveryMethodList) {
					if (item.fDeliveryID == val) {
						// //console.log('333');
						item.checked = true;
						this.fDeliveryID = item.fDeliveryID;
						this.getGoodidPrice();
					} else {
						item.checked = false;
					}
				}
			},
			//根据仓库id查仓库地址
			getAddress() {
				this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get', {
						id: this.listData.fDepotID
					})
					.then(result => {
							this.warehouseAddress = result.obj.fAddress;
							this.warehouseAddressLatAndLng = result.obj;
						},
						rej => {}
					)
			},
			//打开地图
			toMap(obj) {
				// let url = "https://apis.map.qq.com/uri/v1/geocoder?coord=" + obj.flat + "," + obj.flng + "&referer=" + config.TengxunMapKey;
        let url = "https://uri.amap.com/marker?position=" + obj.flng + "," + obj.flat + "&name=" + obj.fAddress
				console.log('obj', url);
				window.open(url, "_black")
			},

			//计数器事件
			handleChange(value) {
				// this.numValue = value;
				// if (this.numValue % this.stepNumValue != 0 && this.stepNumValue != 0) {
				// 	this.numValue = (this.numValue / this.stepNumValue).toFixed(0) * this.stepNumValue;
				// }
				console.log('this.numValue1');
				if (!this.numValue) {
					this.numValue = 0
				}
			},
			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},
			//加入购物车提示
			getTips() {
				const message = this.$message({
					dangerouslyUseHTMLString: true,
					type: 'success',
					showClose: true,
					duration: 1000,
					customClass: 'iconshopcar',
					message: `
					<span style="font-size:16px;color:#10C578;font-weight:bold">成功加入购物车！</span>
					<p style="margin-top:20px;color:#999999">您可以<a   href="#/shoppingCart" class="aselce" style="color:dodgerblue;cursor: pointer;">去购物车结算</a>，或<a class="aselc" href="#/Goods"  style="color:dodgerblue;cursor: pointer;">返回列表</a></p>`
				});
				message.$el.querySelector('.aselc').onclick = () => {
					message.close();
				};
				message.$el.querySelector('.aselce').onclick = () => {
					message.close();
				};

			},
			// 没开通账服通时的提示语
			ACSTips(){
				 
				const message = this.$message({
					dangerouslyUseHTMLString: true,
					type: 'success',
					showClose: true,
					duration: 1000,
					customClass: 'iconshopcar',
					message: `<p  style="color:#999999">亲，请先<a href="#/businessme/AccountPay" class="aselceACSTips" style="color:dodgerblue;cursor: pointer;">开通帐服通钱包</a></p>`
					
				});	
				message.$el.querySelector('.aselceACSTips').onclick = () => {
					message.close();
				};			
			},
			// 获取是否开通过账服通
			getAcs(){
				this.ApiRequestPostNOMess('/api/mall/ebbalance/customer-acs/get-my-ACS').then(
				result=>{
					this.acsData =result.obj
					// 判断不是自营店时 
					if(this.listData.fShopTypeID !=  1){
						// 没有开通账服通
						if(this.acsData == null){
							this.Addcart();    //调用加入购物车成功但不显示加入成功的弹窗
							this.$confirm('亲，请先开通帐服通钱包?', '提示', {
							         confirmButtonText: '前往开通',
							         cancelButtonText: '取消',
							         type: 'warning'
							       }).then(() => {
									   sessionStorage.ApplicationName = '账服通钱包'
							         this.$router.replace({
							         	path: '/businessme/AccountPay',
							         });
									
							       }).catch(() => {   
							       });
							
						}else{
							this.Addcart();
							this.getTips();
						}
					}else{  //如果是自营店，
						this.Addcart();  //调用加入购物车接口
						this.getTips();  //弹出加入购物车成功的消息
					}
					
				})
			},
			//加入购物车成功但不显示加入成功的弹窗
			Addcart(){
				this.ApiRequestPostNOMess('api/mall/ebsale/shopping-cart/create-shopping-cart', {
						fPointPrice: 0,
						fGoodsPrice: 10,
						fAmount: this.numValue,
						fGoodsID: this.fGoodsID,
						fDeliveryID: 2
					})
					.then(result => {
							this.getCarCuont();
						},
						rej => {}
					)
			},
			//加入购物车
			addToCart() {
				this.getAcs()
			},
			//获取购物车总数
			getCarCuont() {
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/shopping-cart/get-shopping-cart-count"
				).then(
					(result) => {
						this.$store.commit("setCarCount", result.obj.number);
					},
					(rej) => {}
				);
			},
			//立即购买
			async buyNowClick(val) {
				if(val.fStatus == 2){
					 this.$message('亲，当前商品已售罄，无法购买');
					 return
				}
				// 非自营店时
				if(this.listData.fShopTypeID !=  1){
					this.ApiRequestPostNOMess('/api/mall/ebbalance/customer-acs/get-my-ACS').then(
					result=>{
						this.acsDataimmediately =result.obj
						if(this.acsDataimmediately == null){
							this.$confirm('亲，请先开通帐服通钱包?', '提示', {
							         confirmButtonText: '前往开通',
							         cancelButtonText: '取消',
							         type: 'warning'
							       }).then(() => {
									   sessionStorage.ApplicationName = '账服通钱包'
							         this.$router.replace({
							         	path: '/businessme/AccountPay',
							         });
									
							       }).catch(() => {   
							       });
							
						}else{
						 let obj = [];
						 let data = {
						 	fGoodsID: this.fGoodsID,
						 	fDeliveryID: 2,
						 	fShopUnitID: this.listData.fShopUnitID,
						 	fAmount: this.numValue
						 };
						 obj.push(data);	
				          this.$store.commit("changeCommonData", obj);
				          this.$router.push({
				          	name: 'FillOrder',
				          	query: {
				          		id: 1, //1是商品详情跳转，2是购物车跳转，3是订单跳转
                      radioIdSelects: JSON.stringify([this.radioIdSelect]),
                      fDeliveryIDs: JSON.stringify([this.fDeliveryIDs])
				          	}
				          })
						}
					})
				}else{  //如果是自营店，
				let obj = [];
				let data = {
					fGoodsID: this.fGoodsID,
					fDeliveryID: 2,
					fShopUnitID: this.listData.fShopUnitID,
					fAmount: this.numValue
				};
				obj.push(data);
				    this.$store.commit("changeCommonData", obj);
				    this.$router.push({
				    	name: 'FillOrder',
				    	query: {
				    		id: 1, //1是商品详情跳转，2是购物车跳转，3是订单跳转
                radioIdSelects: JSON.stringify([this.radioIdSelect]),
                fDeliveryIDs: JSON.stringify([this.fDeliveryIDs])
				    	}
				    })
				}
				//console.log('obj', obj);
				// 判断是否非自营 FShopTypeID 1 自营店 2 非自营店
				// if (this.listData.fShopTypeID !=1) {     2023/2/23典哥说这个接口有问题现在不用了
				// 	// 此接口主要用于判断 当购买非自营店商品时，如果用户没有开通 账服通账户就不可购买
				// 	await this.ApiRequestPostNOMess('api/mall/ebsale/order/get-is-sale-by-goods-id-batch', [{
				// 		id: this.fGoodsID
				// 	}]).then(
				// 		res => {
				// 			// 如果res.obj数组内 isSale 等于false ，那么需要提示用户message内的消息
				// 			if (res.obj[0].isSale == false) {
				// 				this.$message(res.obj[0].message);
				// 				return;
				// 			}
				// 		},
				// 		error => {})
				// }else{
				// 	this.$store.commit("changeCommonData", obj);
				// 	this.$router.push({
				// 		name: 'FillOrder',
				// 		query: {
				// 			id: 1 //1是商品详情跳转，2是购物车跳转，3是订单跳转
				// 		}
				// 	})
				// }

				
			},

			//获取是否关注店铺
			getByShopunitID() {
				// IsFocus 0未关注，1已关注，Focus为关注数量
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/get-by-shopunitID', {
						id: this.listData.fShopUnitID
					})
					.then(result => {
							console.log('获取是否关注店铺', result);
							this.focusOnQuantity = result.obj.focus;
							if (result.obj.isFocus == 0) {
								this.followShow = true;
							} else {
								this.followShow = false;
							}
						},
						rej => {}
					)
			},
			//关注点击事件
			followCreateClick() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/create', {
						fShopUnitID: this.listData.fShopUnitID
					})
					.then(result => {
							//console.log('关注点击事件', result);
							this.getByShopunitID();
							this.$message({
								message: '关注店铺成功！',
								type: 'success'
							});
						},
						rej => {}
					)
			},
			//取消关注事件
			followDeleteClick() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/delete', {
						fShopUnitID: this.listData.fShopUnitID
					})
					.then(result => {
							//console.log('取消关注事件', result);
							this.getByShopunitID();
						},
						rej => {}
					)
			},
			getByShop(){
				this.ApiRequestPost('api/mall/ebshop/service-info/get-by-shop',{
					fShopID:this.listData.fShopUnitID
				}).then(result=>{
					this.fRulObj=result.obj
				})
			},
			//联系客服
			contactServiceClick() {
				// var element = document.createElement("script");
				// element.src = "kefu.cckefu3.com/vclient/?webid=144845&wc=2d5b11";
				// document.body.appendChild(element);
				 window.open(this.fRulObj.fRul, '_blank')
			},
			//进店
			enterTheStore(id) {
				//console.log('id', id);
				this.$router.push({
					name: 'storeList',
					query: {
						id: id
					}
				})
				// this.$router.push({
				// 	name: 'storeList',
				// 	params: {
				// 		id: id
				// 	}
				// })
			},
			//点击热销商品根据id重新加载页面
			hotGoods(obj) {
				window.scrollTo(0, 0);
				console.log('obj', obj);
				this.fGoodsID = obj.goods.fGoodsID;
				this.getCouponInfo();
				this.getGoodsBaseInfo();
				this.getCheckGoodsid()
			},
			//tab点击事件
			handleClick(tab, event) {
				//console.log(tab, event);
			},
			//选择评价条件事件
			evaluateBtnClick(val) {
				for (let item of this.evaluateBtnData) {
					if (val == item.name) {
						item.checked = true;
					} else {
						item.checked = false;
					}
				}
			},
			//评价点赞事件
			thumbClick() {
				this.thumbActive = !this.thumbActive;
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				//console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
			},
			// 新增弹窗提交成功回调
			handleClose() {
				this.showModel = false;
			},
			// 新增验证事件
			submitForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						// this.addSubmit()
					} else {
						return false
					}
				})
			},
			//获取当前商品是否有活动
			getGoodsActivityInfo() {
				this.ApiRequestPost('api/mall/ebactivity/coupon-rule-bill/get-goods-activity-info', {
						fGoodsID: this.fGoodsID
					})
					.then(result => {
							console.log('获取当前商品活动', result);
							if (result.obj) { //有商品活动
								// debugger
								this.activityInfo = result.obj
								let beforeshow = new Date(this.activityInfo.fRemindTime).getTime(); //活动开始展示时间
								let start = new Date(this.activityInfo.fBeginTime).getTime(); //活动开始时间
								let end = new Date(this.activityInfo.fEndTime).getTime(); //活动结束时间
								let now = new Date().getTime();
								if(this.activityInfo !=null  && this.activityInfo.fCouponRuleID== -1){
									if(this.activityInfo.fIconPath!=null &&this.activityInfo.fIconPath!="")
									{
										this.hasShowAl = true
									}else{
										return;
									}
									
								}else{
									if (now < beforeshow) {
										//活动未开始
										this.hasActivity = false
										return;
									} else {
										this.hasActivity = true
										this.hasShowAl = true;
									}
									if (now >= beforeshow && now < start) {
										console.log(start - now)
										this.showactivitytext = "距离活动开始："
										this.usedTime = (start - now)
									}
									if (now > end) {
										console.log('活动已结束')
										this.hasActivity = false
										this.hasShowAl = false;
									}
									if (now >= start && now < end) {
										console.log(end - now)
										this.showactivitytext = "距离活动结束："
										this.usedTime = (end - now)
									
									}
									
								}
								

								// if(new Date().getTime() > new Date(this.activityInfo.fRemindTime).getTime()  &&  new Date().getTime() < new Date(this.activityInfo.fBeginTime).getTime()){
								// 	this.showactivitytext = "距离活动开始："
								// 	this.usedTime = (new Date(this.activityInfo.fBeginTime).getTime() - new Date().getTime() )/1000
								// 	console.log('活动还没开始，已进入倒计时开始的>>开始倒计时')
								// }	
								// if(new Date().getTime() > new Date(this.activityInfo.fEndTime).getTime()){
								// 	console.log('活动已结束')
								// 	this.countDownShow = false;
								// 	this.hasActivity = false;
								// }
								// if(new Date().getTime() >= new Date(this.activityInfo.fBeginTime).getTime() && new Date().getTime() < new Date(this.activityInfo.fEndTime).getTime()){
								// 	this.showactivitytext = "距离活动结束："
								// 	this.usedTime = (new Date(this.activityInfo.fEndTime).getTime() - new Date().getTime())/1000
								// 	console.log('距离活动结束还剩多少时间==》》已开始活动')
								// }
							} else {
								this.hasActivity = false
							}
						},
						rej => {}
					)
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-message__closeBtn {
		fonr-size:40px;
	}
	/deep/.el-message.is-closable .el-message__content {
		padding-left: 16px;
	}
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.top-box {
		min-height: 488px;
	}

	.box-left {
		display: flex;
		flex-direction: column;

		.outline-imgs {
			width: 349px;
			height: 349px;

			&::v-deep img {
				width: 100%;
				height: 100%;
			}

			.outline-img {
				width: 325px;
				height: 325px;
				border: 1px solid #f2f2f2;
				padding: 10px;
				border-radius: 10px;

				&::v-deep img {
					width: 100%;
					height: 100%;
					border-radius: 10px;
				}
			}

			.activity-img-box {
				width: 50%;
				height: 50%;
			}

			.activityImg {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}

			.outline-piclist {
				width: 307px;
				height: 80px;
				overflow: hidden;
				margin: 10px 0;
				position: relative;
				padding: 0 20px;

				.piclist-box {
					cursor: pointer;
					position: absolute;
					left: 20px;
					top: 0;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
				}

				.left-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					left: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.right-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.piclist-img {
					width: 78px;
					height: 78px;
					margin-right: 10px;
					border: 1px solid #f2f2f2;

					img {
						width: 78px;
						height: 78px;
					}

					&:hover,
					&.active {
						border: 1px solid #f03333;
					}
				}
			}
		}
	}

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.activity-box {
		width: 730px;
		height: 40px;
	}

	.box-right {}

	.commodityInformation-box {
		width: 704px;
	}

	.dashed {
		border-right: 1px dashed #FA2C60;
		margin: 0 2px;
	}

	.receive-box {
		border-bottom: 1px dashed #FA2C60;
	}

	.factory-box {
		min-width: 95px;
		height:24px;
		line-height: 24px;
		border-radius: 5px;
	}
    .bcgEAF3FF{
		background-color: #EAF3FF;
	}
	.factory-hover:hover {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.address-box {
		height: 46px;
		border:1px dashed #E1edff;
		line-height: 40px;
		border-radius: 10px;
	}

	.tatle-box {
		width: 65px;
	}

	.btn-box {
		width: 235px;
		height: 45px;
		line-height: 45px;
		box-sizing: border-box;
	}

	.btn-box1 {
		width: 725px;
		height: 45px;
		line-height: 45px;
		box-sizing: border-box;
	}

	.b-box-left {
		width: 260px;
		height: auto;
		box-sizing: border-box;
	}

	.img-box {
		width: 45px;
		height: 45px;
		box-sizing: border-box;
	}

	.img-style {
		width: 35px;
		height: 35px;
	}

	.follow-box {
		width: 60px;
		height: 24px;
		line-height: 24px;
	}

	.shopStarRating-box {
		width: 150px;
		height: 22px;
		line-height: 22px;
		box-sizing: border-box;
	}

	.el-rate::v-deep {
		height: 15px;

		.el-rate__icon {
			margin-right: 0;
			font-size: 15px;
		}
	}

	.shopBtn-box {
		width: 110px;
		height: 30px;
		line-height: 28px;
		box-sizing: border-box;
	}

	.left-middle {
		padding:17px;
		background-image: linear-gradient(to bottom, #9FC8FF, #D0F3FF);
		border-radius: 10px;
	}

	.b-box-right {
		width: 820px;
		min-height: 521px;
		height: auto;
	}

	.img-box1 {
		width: 107px;
		height: 107px;
	}

	.img-style1 {
		width: 90px;
		height: 90px;
	}

	.title-box {
		width: 107px;
	}

	.el-tabs--border-card::v-deep {
		background: #FFF;
		border: none;
		box-shadow: none;

		.el-tabs__header {
			background-color: #F8F9FE;
			border: 1px solid #f2f2f2;
			margin: 0;
		}

		.el-tabs__item:hover {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}

		.is-active {
			@include themify($themes) {
				color: themed('themes_color');
			}

			background-color: #FFF;
			border-right-color: #f2f2f2;
			border-left-color: #f2f2f2;
		}

		.el-tabs__content {
			padding: 10px 0;
		}
	}

	.evaluateTitle-box {
		height: 63px;
	}

	.img-box2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.img-style2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.whole {
		width: 65px;
		height: 24px;
		line-height: 24px;
	}

	.evaluate-btn {
		height: 24px;
		line-height: 24px;
	}

	.shopRulesDialog::v-deep {
		.el-dialog__body {
			padding: 10px 20px 20px 20px;
		}
	}

	.el-input-number--mini::v-deep {
		line-height: 36px;
	}

	.cub {
		color: dodgerblue;
		cursor: pointer;
	}

	.iconshopcar {
		.el-message {
			.el-icon-success {
				font-size: 32px !important;
			}
		}
	}

	.iconTopLeft {
		top: 0;
		left: 0;
	}

	.iconTopRight {
		top: 0;
		right: 0;
	}


	.iconBottomRight {
		bottom: 0;
		right: 0;
	}

	.iconBottomLeft {
		bottom: 0;
		left: 0;
	}

	.delete-btn {
		top: 7px;
		right: 7px;
		z-index: 1000;
		color: #9e9e9e;
	}

	.play-img {
		width: 50px;
		height: 50px;
		background-image: url(../../../assets/imgs/goods/play1.png);

		&:hover {
			cursor: pointer;
			background-image: url(../../../assets/imgs/goods/play2.png);
		}
	}
	.anotherStyle{
		float:right
	}
	.box-sellout {
	  min-width: 70px;
	  width: 100px;
	  height: 100px;
	  position: absolute;
	  right: 15px;
	  top: 15px;
	  z-index: 100;
	  padding: 0 10px;
	  line-height: 24px;
	}
  .activity {
    padding-bottom: 20px;
    .tit {
      color: red;
      font-weight: 700;
      display: inline-block;
      margin-left: 17px;
    }
    .btn {
      // float: left;
      color: #0173FE;
      cursor: pointer;
      display: inline-block;
      margin-left: 15px;
    }
    .activityInfo {
      padding: 10px 17px;
      span {
        color: #0173FE;
      }
    }
  }
  .fence {
    text-decoration: underline;
  }
  /deep/.el-radio__label {
    padding: 0;
  }
  .map {
    ::v-deep .el-dialog__body {
      padding: 0;
    }
  }
  .OrderText{
  	line-height: 35px;
  	color: #FF3333;
  }
  a:hover{
  	border-bottom: 1px solid #0173FE;
  }
  ::v-deep .el-dialog__body {
  		padding: 10px 20px 20px 20px;
  }
  .img {
    background: url('../../../assets/car.png') no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align:middle;
  }
  .StandardBcgBox{
	  background-color: #E1EDFF;
	   border-radius:10px;
  }
  .activityBagBox{
	  background-color: #E1251A;
	  border-radius:0 0 10px 10px;
  }
  /deep/.el-input__inner{
	  height: 36px !important;
	  line-height: 36px !important;
	  border: 1px solid #DCDFE6 !important;
  }
  /deep/.el-input-number{
	  line-height: 34px !important;
  }
</style>
